@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ck-toolbar {
  background-color: #EAECF0 !important;
  border-bottom: 0px !important;
  border: 1px solid #EAECF0 !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.ck-editor__main .ck-editor__editable_inline {
  border-top: 0px !important;
  border: 1px solid #EAECF0 !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  background: #FFFFFF !important;
  min-height: 500px !important;
}

#template-debitur .ck-editor__main .ck-editor__editable_inline {
  min-height: 150px !important;
}